var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_c('section',{staticClass:"banner",class:{'active': _vm.mounted}},[_vm._m(0),_vm._m(1)]),_vm._m(2),_c('section',{staticClass:"members"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"member-outer"},[_c('div',{staticClass:"member-list"},[_c('div',{staticClass:"member-card member1"},[_c('div',{staticClass:"member-img"},[_c('img',{attrs:{"src":require('@/assets/images/about/p1.png')}})]),_vm._m(3)]),_c('div',{staticClass:"member-card member2"},[_c('div',{staticClass:"member-img"},[_c('img',{attrs:{"src":require('@/assets/images/about/p2.png')}})]),_vm._m(4)]),_c('div',{staticClass:"member-card member3"},[_c('div',{staticClass:"member-img"},[_c('img',{attrs:{"src":require('@/assets/images/about/p3.png')}})]),_vm._m(5)])])])])]),_c('section',{staticClass:"corlab"},[_c('p',[_vm._v("合作夥伴 邊境實驗室")]),_c('a',{attrs:{"href":"https://borderlabs.co/","target":"_blank"}},[_c('img',{attrs:{"src":require('@/assets/images/about/borderlabs_logo.svg')}})])]),_c('DFCDFooter')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"parallax-banner",attrs:{"id":"scene"}},[_c('div',{staticClass:"banner-img"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"underline"}),_c('div',{staticClass:"title-text"},[_c('span',[_vm._v("About")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"py-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row flex-lg-row-reverse align-items-center mx-auto",staticStyle:{"max-width":"800px"}},[_c('div',{staticClass:"col-12 col-lg mt-lg-4 mb-5 py-5"},[_c('div',{staticClass:"mb-5"},[_c('h3',{staticClass:"mb-1"},[_vm._v("大坊設計")]),_c('p',[_vm._v(" 人文生活與藝術的設計哲學是大坊設計不變的初心。"),_c('br'),_vm._v(" 對我們來說建築＆室內不僅僅是一個框架的包覆而已，更是生活與設計在空間中的對話。"),_c('br'),_vm._v(" 空間設計強調生活與機能並行，並在每一處細節上堆砌出精緻的生活質韻。"),_c('br'),_vm._v(" 完全客製化的空間規劃，運用生活體驗、空間改造、傢俱與軟裝選物等面向打造使用者的場域氛圍。 ")])]),_c('div',[_c('h3',{staticClass:"mb-1"},[_vm._v("DFCD")]),_c('p',[_vm._v(" The design philosophy of humanistic life and art is the unchanging original intention of DFCD design for us, architecture & interior is not just a frame covering, but also a dialogue between life and design in space."),_c('br'),_vm._v(" The space design emphasizes the parallelism of life and function, and builds up exquisite life quality in every detail.completely customized space planning, using life experience, space transformation, furniture and soft decoration selection, etc. to create the user's site atmosphere. ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"member-desc"},[_c('div',{staticClass:"member-title"},[_c('p',[_vm._v("DIRECTOR")])]),_c('div',{staticClass:"member-name"},[_vm._v("方仁廣 Rego")]),_c('p',{staticClass:"member-intro intro-en"},[_vm._v(" National Yunlin university of science& technology"),_c('br'),_vm._v(" Department & Architecture & interior design .Bachelor ")]),_c('p',{staticClass:"member-intro"},[_vm._v(" 大坊設計主理人方仁廣目前專注於建築、空間規劃與親子露營生活為樂趣"),_c('br')]),_c('p',{staticClass:"member-intro"},[_vm._v(" 並以人文、生活、藝術建立空間與使用者生活之間的連結，我認為設計不只是創造生活空間上的滿足，更應該是要空間生活的進化。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"member-desc"},[_c('div',{staticClass:"member-title"},[_c('p',[_vm._v("HOME DECORATOR")])]),_c('div',{staticClass:"member-name"},[_vm._v("周秀美 Amber")]),_c('p',{staticClass:"member-intro intro-en"},[_vm._v(" Tungnan university the englineering college"),_c('br'),_vm._v(" 傢飾設計師 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"member-desc"},[_c('div',{staticClass:"member-title"},[_c('p',[_vm._v("3D VISUALIZER")])]),_c('div',{staticClass:"member-name"},[_vm._v("曹文揚 Kevin")]),_c('p',{staticClass:"member-intro intro-en"},[_vm._v(" China university of science & technology"),_c('br'),_vm._v(" 3D MAX設計師 ")])])
}]

export { render, staticRenderFns }