<template>
  <div class="about">
    <section class="banner" :class="{'active': mounted}">
      <div class="parallax-banner" id="scene">
        <div class="banner-img"></div>
      </div>
      <div class="title">
        <div class="underline"></div>
        <div class="title-text"><span>About</span></div>
      </div>
    </section>

    <section class="py-5">
      <div class="container">
        <div class="row flex-lg-row-reverse align-items-center mx-auto" style="max-width: 800px;">
          <!-- <div class="col-12 col-lg-3 text-right">
            <img class="img-fluid" src="@/assets/images/about/about-main.png" />
            <p class="pt-3">
              <span class="pr-3">方</span> 仁 廣<br/>
              Rego<br/>
              Designer/Chief Brand Officer
            </p>
          </div> -->
          <div class="col-12 col-lg mt-lg-4 mb-5 py-5">
            <div class="mb-5">
              <h3 class="mb-1">大坊設計</h3>
              <p>
                人文生活與藝術的設計哲學是大坊設計不變的初心。<br/>
                對我們來說建築＆室內不僅僅是一個框架的包覆而已，更是生活與設計在空間中的對話。<br/>
                空間設計強調生活與機能並行，並在每一處細節上堆砌出精緻的生活質韻。<br/>
                完全客製化的空間規劃，運用生活體驗、空間改造、傢俱與軟裝選物等面向打造使用者的場域氛圍。
              </p>
            </div>
            <div>
              <h3 class="mb-1">DFCD</h3>
              <p>
                The design philosophy of humanistic life and art is the unchanging original intention of DFCD design for us, architecture & interior is not just a frame covering, but also a dialogue between life and design in space.<br/>
                The space design emphasizes the parallelism of life and function, and builds up exquisite life quality in every detail.completely customized space planning, using life experience, space transformation, furniture and soft decoration selection, etc. to create the user's site atmosphere.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="members">
      <div class="container">
        <div class="member-outer">

          <div class="member-list">
  
            <div class="member-card member1">
              <div class="member-img">
                <img :src="require('@/assets/images/about/p1.png')"/>
              </div>
              <div class="member-desc">
                <div class="member-title">
                  <p>DIRECTOR</p>
                </div>
                <div class="member-name">方仁廣 Rego</div>
                <p class="member-intro intro-en">
                  National Yunlin university of science& technology<br/>
                  Department & Architecture & interior design .Bachelor
                </p>
                <p class="member-intro">
                  大坊設計主理人方仁廣目前專注於建築、空間規劃與親子露營生活為樂趣<br/>
                </p>
                <p class="member-intro">
                  並以人文、生活、藝術建立空間與使用者生活之間的連結，我認為設計不只是創造生活空間上的滿足，更應該是要空間生活的進化。
                </p>
              </div>
            </div>
    
            <div class="member-card member2">
              <div class="member-img">
                <img :src="require('@/assets/images/about/p2.png')"/>
              </div>
              <div class="member-desc">
                <div class="member-title">
                  <p>HOME DECORATOR</p>
                </div>
                <div class="member-name">周秀美 Amber</div>
                <p class="member-intro intro-en">
                  Tungnan university the englineering college<br/>
                  傢飾設計師
                </p>
              </div>
            </div>
    
            <div class="member-card member3">
              <div class="member-img">
                <img :src="require('@/assets/images/about/p3.png')"/>
              </div>
              <div class="member-desc">
                <div class="member-title">
                  <p>3D VISUALIZER</p>
                </div>
                <div class="member-name">曹文揚 Kevin</div>
                <p class="member-intro intro-en">
                  China university of science  & technology<br/>
                  3D MAX設計師
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="corlab">
      <p>合作夥伴 邊境實驗室</p>
      <a href="https://borderlabs.co/" target="_blank"><img :src="require('@/assets/images/about/borderlabs_logo.svg')" /></a>
    </section>

    <DFCDFooter></DFCDFooter>
  </div>
</template>

<script>
import DFCDFooter from '@/components/DFCDFooter.vue';
import simpleParallax from 'simple-parallax-js';

export default {
  name: 'About',
  components: {
    DFCDFooter,
  },
  data() {
    return {
      mounted: false,
    }
  },
  props: {
  },
  created() {
  },
  mounted() {
    setTimeout(() => {
      this.mounted = true;
    }, 600);
    
    setTimeout(() => {
      const scene = document.getElementById('scene');
      const p = new simpleParallax(scene, {
        orientation: 'down',
        scale: 1.5
      });
      console.log(p);
    }, 500);
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
  }

}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/basic";

  .banner {
    position: relative;
    width: 100%;
    height: 100dvh;
    height: 100vh;
    overflow: hidden;

    .parallax-banner {
      position: relative;
      width: 100%;
      height: 100dvh;
      height: 100vh;
      .banner-img {
        width: 105%;
        height: 100%;
        background-image: url('~@/assets/images/About_01.jpg');
        background-size: cover;
        background-position: bottom center;
        background-repeat: no-repeat;
        transition: opacity 2s ease;
        // transform: translate(0%, 0);
        opacity: 0;
      }
    }

    .title {
      position: absolute;
      bottom: 15%;
      right: 6rem;
      width: 30%;
      display: flex;
      align-items: flex-end;
      column-gap: 1rem;
      @include smaller-than-medium {
        right: 15%;
        left: 0%;
        width: initial;
      }
      .underline {
        flex: 1 1;
        position: relative;
        &:after {
          display: block;
          content: '';
          width: 0;
          transition: all .8s .5s ease;
          border-bottom: solid 2px $color-link-black;
        }
        margin-bottom: .5rem;
      }
      .title-text {
        flex: 0 0 auto;
        overflow: hidden;
        span {
          font-size: 1.5rem;
          display: inline-block;
          transform: translate(0, 100%);
          transition: all .8s 1.3s ease;
        }
      }
    }

    &.active {
      .banner-img {
        // transform: translate(-4.7%, 0);
        opacity: 1;
      }
      .title {
        .underline {
          &:after {
            width: 100%;
          }
        }
        .title-text {
          span {
            transform: translate(0, 0);
          }
        }
      }
    }
  }

  .members {
    --member3-width: 40%;
    padding: 7.6rem 0;

    @include smaller-than-medium {
      --member3-width: 0;
    }

    .member-outer {
      position: relative;
      margin: 0 auto;
      max-width: 60rem;
    }
    .member-list {
      position: relative;
      padding-left: var(--member3-width);
      // background-color: aqua;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      .member-card {
        display: flex;
        column-gap: 2rem;
        align-items: flex-end;

        @include smaller-than-medium {
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          margin-bottom: 3rem;
        }
        .member-img {
          flex: 0 0 40%;
          @include smaller-than-medium {
            flex: 0 0 70%;
            margin-bottom: 1rem;
          }
          img {
            width: 100%;
          }
        }
        .member-desc {
          flex: 1 1;
          font-size: .75rem;
          margin-bottom: 1rem;
          @include smaller-than-medium {
            flex: 0 0 100%;
            // margin-left: -15px;
            // margin-right: -15px;
          }
          .member-title {
            display: flex;
            width: 100%;
            column-gap: .5rem;
            align-items: flex-end;
            margin-bottom: 1rem;
            margin-top: 1rem;
            >p {
              margin-bottom: 0;
              font-size: 1.25rem;
              flex: 0 0 auto;
            }
            @include smaller-than-medium {
              margin-bottom: .25rem;
            }
            &:after {
              content: '';
              display: block;
              flex: 1 1;
              border-bottom: solid 1px #000;
              margin-bottom: .5rem;
              @include smaller-than-medium {
                margin-right: -15px;
                

              }
            }
          }

          .member-name {
            margin-bottom: .5rem;
            @include smaller-than-medium {
              margin-bottom: 1rem;
            }
          }

          .member-intro {
            margin-bottom: .5rem;
            // text-align: justify;
            &.intro-en {
              margin-bottom: 1rem;
            }
            @include smaller-than-medium {
              padding-left: 10%;
              padding-right: 10%;
            }
          }
        }

        @include bigger-than-medium {
          &.member3 {
            position: absolute;
            left: 0;
            // right: 70%;
            right: calc(100% - var(--member3-width));
            top: 50%;
            transform: translate(1rem, -50%);
            flex-wrap: wrap;
            column-gap: 0;
            justify-content: flex-end;
            .member-img {
              flex: 0 0 55%;
              margin-right: 2rem;
            }

            .member-desc {
              flex: 0 0 80%;
            }
          }

        }
      }
    }
  }

  .corlab {
    padding: 7rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 3rem;
    @include smaller-than-medium {
      // flex-wrap: wrap;
      flex-direction: column;
      column-gap: 0;
      row-gap: 1rem;
    }
    p {
      margin: 0;
      font-size: .75rem;
    }
    img {
      width: 15rem;
    }
    a {
      text-decoration: none;
      color: initial;
      &:hover {
        text-decoration: none;
      }
    }
  }

</style>
